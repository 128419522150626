






















































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { SnackbarProperties } from "@/components/shared/SnackbarView.vue";
import { AxiosResponse } from "axios";
import LiveSesssionProductIntroducingView from "../common/AbstractLiveSessionProductIntroducingView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CommonUtil from '@/utilities/CommonUtil';

@Component({})
export default class LiveSessionProductIntroducingView extends LiveSesssionProductIntroducingView {
	@Prop() private snackbar: SnackbarProperties;

    public get product() {
        return this.properties.product;
    }

    public mounted() {
        this.properties.events.subscribe("loaded", this.load);
		this.properties.events.subscribe("open-default", this.openDefault);
        this.inputQuantity = this.$el.querySelector('#quantity');
        this.inputWeight = this.$el.querySelector('#weight');

        this.load();
    }

    public destroyed() {
        this.properties.events.remove("loaded", this.load);
		this.properties.events.remove("open-default", this.openDefault);
    }

	public async openDefault() {
		if (this.$data.saving) {
			return;
		}
		
		try {
			const liveSessionId = this.liveSession?.id ?? 0;
			if (liveSessionId !== 0) {
				this.properties.product = await this.newProduct();
				await this.save(true);

				SnackbarProperties.reset(this.snackbar);
      		    this.snackbar.timeout = 3000;
            	this.snackbar.alignment = "top";
            	this.snackbar.message = this.$t("message.default-product-opened-successfully");
            	this.snackbar.visible = true;
			}
			this.updateFormatter();
		} catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
		}
	}

    public async save(open: boolean) {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
			const liveSessionId = this.liveSession?.id ?? 0;
            if (liveSessionId !== 0) {
                const product = CommonUtil.clone(this.properties.product);
                product.confirmationDialog = this.$data.confirmationDialog;
                product.liveSessionId = liveSessionId;
                product.introducing = true;

				var r: AxiosResponse<any>;
                if (open) {
                    r = await this.liveSessionProductService.post(product);
                } else {
                    r = await this.liveSessionProductService.put(product);
                }
            	this.properties.product = r.data;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async ended() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
			const liveSessionId = this.liveSession?.id ?? 0;
            if (liveSessionId !== 0) {
                const product = CommonUtil.clone(this.properties.product);
                product.confirmationDialog = this.$data.confirmationDialog;
                product.introducing = false;
                await this.liveSessionProductService.put(product);
				this.properties.product = product;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async clear() {
        try {
            this.properties.product = await this.newProduct();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }
}
