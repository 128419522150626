import AbstractService from "./AbstractService";
import Axios from "axios";

export default class LiveSessionProductService extends AbstractService {
    private controller = "live-session-products";

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public post(liveSessionProduct: any) {
        const d = { liveSessionProduct };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.post<any>(url, d, this.makeConfig());
    }

    public put(liveSessionProduct: any) {
        const d = { liveSessionProduct };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public delete(liveSessionProduct: any) {
        const d = { liveSessionProduct };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.delete<any>(url, this.makeConfig(true, d));
    }
}