
import { Vue, Component, Prop } from "vue-property-decorator";
import { LiveSessionInfoProperties } from "../common/CommonLiveSessionProperties";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { Options } from "autonumeric";
import LiveSessionProductService from "@/services/LiveSessionProductService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CommonUtil from '@/utilities/CommonUtil';
import SystemSettingService from "@/services/SystemSettingService";
import AutoNumericUtil from "@/utilities/AutoNumericUtil";

@Component({
    data: () => ({
        expanded: false,
        confirmationDialog: true,
        saving: false
    })
})
export default class LiveSessionStreamerProductIntroducingView extends Vue {
    @Prop() protected properties: LiveSessionInfoProperties;
    @Prop() protected alertDialog: AlertDialogProperties;
    protected liveSessionProductService = new LiveSessionProductService();
    protected systemSettingService = new SystemSettingService();
    protected inputQuantity: HTMLInputElement;
    protected inputWeight: HTMLInputElement;

	public get liveSession() {
		return this.properties.liveSession;
	}

    public get product() {
        return this.properties.product;
    }

    public updateFormatter() {
        setTimeout(() => {
            const options1: Options = {
                minimumValue: "0",
                historySize: 0,
                outputFormat: "number",
                digitGroupSeparator: "",
                unformatOnHover: false,
                unformatOnSubmit: false
            };
            const options2 = CommonUtil.clone(options1);
            options2.decimalPlaces = 0;

            AutoNumericUtil.findOrNew(this.inputQuantity, null, options2)
                .set(this.product.quantity ?? 0);
            AutoNumericUtil.findOrNew(this.inputWeight, null, options1)
                .set(this.product.weight ?? 0);
        }, 150);
    }

    public async newProduct() {
        const r = await this.systemSettingService.liveSessionSetting();
        return {
			code: r.data.productCode,
			name: r.data.productName,
            quantity: 0,
            price: 0,
            weight: r.data.productWeight,
			introducing: false
        };
    }

    public async load() {
        try {
			const liveSessionId = this.liveSession?.id ?? 0;
            if (liveSessionId !== 0) {
                const r = await this.liveSessionProductService.get({
                    liveSessionId: liveSessionId,
                    introducing: true
                });
                const product = r.data.liveSessionProducts[0] || await this.newProduct();
                this.properties.product = product;
                this.$data.confirmationDialog = product.confirmationDialog ?? false;
            } else {
                this.properties.product = await this.newProduct();
            }
            this.updateFormatter();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }
}
